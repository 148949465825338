@import '@objectedge/pecai-storefront-ds/src/styles/colors/colors';
@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.main {
  background: $white;
  padding: 24px;
  margin-bottom: 24px;
  margin-top: 24px;

  &__title {
    color: $secondary;
    line-height: 120%;
    margin-bottom: 24px;
  }

  &__categories {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 30px;
    row-gap: 24px;
  }

  &__category {
    padding: 12px;
    background: $light;
    color: $secondary;
    width: 190px;
    min-height: 68px;
    display: flex;
    align-items: flex-end;
    border-radius: 4px;
    cursor: pointer;
  }

  @include media-breakpoint-down('md') {
    padding: 16px;

    &__category {
      width: 136px;
    }
  }
}
