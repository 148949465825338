@import '@objectedge/pecai-storefront-ds/src/styles/colors/colors';
.container {
  width: 100%;
  background-color: $white;
  border-top: 1px solid #e1e0e7;
}

.main {
  padding: 40px 0;

  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    p {
      color: $primary-900;
    }
  }

  &__section {
    display: flex;
    justify-content: space-around;

    a {
      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    p {
      color: $gray-100;
    }

    h6 {
      color: $primary-800;
    }
  }
}
