@import '@objectedge/pecai-storefront-ds/src/styles/colors/colors';
@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.main {
  background-color: $white;
  border-radius: 4px;
  padding: 25px;
  max-width: 313px;

  @include media-breakpoint-down('lg') {
    padding: 25px 10px;
  }

  @include media-breakpoint-down('md') {
    max-width: 2000px;
  }
}
