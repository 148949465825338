@import '@objectedge/pecai-storefront-ds/src/styles/variables';
@import '@objectedge/pecai-storefront-ds/src/styles/colors/colors';

.searchIsolatedContainer {
  width: 100%;
  max-width: 23rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  form {
    width: 100%;

    .searchInput {
      width: 100%;
      max-width: 23rem;
      height: 5rem;

      div > input {
        border-radius: 0.25rem;
        border: 1px solid $primary-900;
        text-align: center;

        &:focus {
          border-radius: 0.25rem;
          border: 1px solid $primary-900;
        }
      }
    }

    .buttonContainer {
      width: 100%;
      max-width: 23rem;

      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;

      @include media-breakpoint-down(sm) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column-reverse;
      }

      .clearInputButton, .ctaSearchButton {
        width: 47%;
        height: 2.668rem;
        border-radius: 0.5rem;
        text-transform: none;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-down(sm) {
          margin-bottom: 0.5rem;
          width: 100%;
        }
      }
    }
  }
}

