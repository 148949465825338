@import '@objectedge/pecai-storefront-ds/src/styles/variables.scss';

.main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;
  }

  &__input {
    width: 100%;
  }

  &__button {
    width: 100%;
    flex-basis: 100%;

    @media screen and (min-width: 768px) {
      margin-top: 13px;
      flex-basis: 254px;
      margin-left: 16px;
      max-width: 195px;
    }
  }
}
