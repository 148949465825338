@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.heading {
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-down(sm) {
    *:nth-child(2) {
      display: none;
    }
  }

  h2 {
    p {
      margin-bottom: 0px;
      color: $primary-900;
    }
    b {
      color: $primary-500;
    }
    &::after {
      content: '';
      width: 356px;
      display: block;
      border-bottom: 4px solid $primary-100;

      @include media-breakpoint-down(lg) {
        width: 128px;
      }
    }
  }

  &.center {
    align-items: center;
    justify-content: center;
    *:nth-child(2) {
      display: none;
    }
    h2 {
      align-items: center;
    }
  }

  &.left {
    align-items: left;
    text-align: left;
  }

  &.right {
    align-items: end;
    text-align: right;
  }
}
