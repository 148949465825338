@import '@objectedge/pecai-storefront-ds/src/styles/variables';
@import '@objectedge/pecai-storefront-ds/src/styles/colors/colors';

.main {
  display: grid;
  padding: 0;

  grid-template-areas: 'a a a a a';
  justify-content: space-between;
  *:nth-child(n + 6) {
    display: none;
  }

  @include media-breakpoint-only(xxl) {
    grid-template-areas: 'a a a a a';
    *:nth-child(n + 6) {
      display: none !important;
    }
  }

  @include media-breakpoint-only(xl) {
    grid-template-areas: 'a a a a';
    *:nth-child(n + 5) {
      display: none;
    }
  }

  @include media-breakpoint-only(lg) {
    grid-template-areas: 'a a a';
    justify-content: center;
    gap: 2rem;
    *:nth-child(n + 4) {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    justify-content: center;
    gap: 2rem;
    *:nth-child(n + 5) {
      display: none;
    }
  }

  @include media-breakpoint-only(md) {
    grid-template-areas: 'a a' 'a a';
  }

  @include media-breakpoint-down(sm) {
    grid-template-areas: 'a a';
    gap: 0.5rem;
  }

  @media screen and (max-width: 360px) {
    grid-template-areas: 'a';
  }
}
