@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.featuredBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    width: 100%;
  }

  img {
    object-fit: contain;
  }
}
