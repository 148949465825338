@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.stepper {
  background-color: $white;
  height: 56px;

  & > div {
    box-shadow: 0px 8px 8px -8px rgba($secondary, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $secondary;
    svg path {
      fill: $secondary;
    }

    &.active {
      box-shadow: 0px 2px 4px rgba($secondary, 0.4);
      color: $white;
      background-color: $secondary;
      border: solid 2px #ffffff;

      svg path {
        fill: $white;
      }
    }

    &.done {
      color: $success;
      svg path {
        fill: $success;
      }
    }

    & > p {
      text-align: center;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    @include media-breakpoint-up(md) {
      svg {
        margin-right: 12px;
      }
    }
  }
}
