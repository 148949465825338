@import '@objectedge/pecai-storefront-ds/src/styles/colors/colors';
@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.myAccountMenu {
  background-color: $light;
  max-width: 248px;
  min-width: 248px;
  box-shadow: 1px 0px 0.1px rgba(51, 50, 55, 0.1);
  display: flex;
  flex-direction: column;

  svg {
    fill: $secondary-500;
  }

  @include media-breakpoint-up(md) {
    padding: 32px 0;
  }
}

.menuItem {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  color: $secondary !important;
  text-decoration: none !important;
  column-gap: 0.6em;

  &.active {
    background-color: lighten($primary, 25%);
    border: 1px solid $primary;
  }
}

.divider {
  width: 100%;
  padding: 16px;
}
