@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.slider {
  :global(.slick-list) {
    margin: -16px;
    @include media-breakpoint-down('sm') {
      margin: -8px;
    }
  }
  :global(.slick-slide > div) {
    margin: 16px;
    @include media-breakpoint-down('sm') {
      margin: 16px 8px;
    }
  }
}

.dotSpacer {
  height: 50px;
}
