@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.featuredLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 128/50;
  border: 1px solid $gray-100;
  box-shadow: 0px 4px 4px rgba(151, 151, 151, 0.25);
  background: $white;

  img {
    padding: 7%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
