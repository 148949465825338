@import '@objectedge/pecai-storefront-ds/src/styles/colors/colors';
.container {
  width: 100%;
  background-color: $white;
  border-top: 1px solid #e1e0e7;
}

.main {
  padding: 20px;
  p {
    color: $gray-100;
  }

  h6 {
    color: $primary-800;
  }

  &__info {
    margin-top: 24px;
    text-align: center;
    p {
      color: $primary-900;
    }
  }
}
