@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.form {
  &__row {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__input {
    @media screen and (min-width: 768px) {
      flex-basis: 33.3%;
    }
  }

  &__text {
    margin: 0 16px;
    text-align: center;

    @media screen and (min-width: 768px) {
      margin-top: 26px;
    }
  }

  &__button {
    width: 100%;
    flex-basis: 100%;

    @media screen and (min-width: 768px) {
      margin-top: 13px;
      flex-basis: 254px;
      margin-left: 16px;
      max-width: 195px;
    }
  }
}

.makeForm {
  display: flex;
  flex-direction: column;
  height: auto;

  .loader {
    position: relative;
    height: 256px;
  }

  &__btn {
    text-transform: none;
    align-self: end;
  }

  .makeList {
    width: 100%;
    height: 256px;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;

    @media screen and (max-width: 580px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 350px) {
      grid-template-columns: 1fr;
    }

    & > div {
      display: flex;
      flex-direction: column;
      width: 104px;
      height: 78px;
      border-radius: 4px;
      align-items: center;
      cursor: pointer;
      border: 2px solid transparent;
      &:hover {
        background-color: rgba($primary, 0.1);
      }
      &:active {
        border: 2px solid $primary;
      }
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: $secondary;
      border-radius: 2px;
    }
    .makeRoot {
      text-align: center;
      margin-bottom: 24px;
      p {
        padding-top: 6px;
      }
    }
  }
}

.customerGarage {
  display: flex;
  flex-direction: column;
  height: 100%;

  .vehicleList {
    overflow-y: auto;
    margin-bottom: 16px;
    & > div {
      background-color: $white;
      cursor: pointer;
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 6px 16px;
      border: 1px solid $grey;
      margin-bottom: 16px;
      &.selected {
        border: 1px solid $primary;
      }
      &:hover {
        background-color: rgba($primary, 0.1);
      }
    }
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  svg {
    margin-right: 1rem;
    cursor: pointer;
  }

  h3 {
    margin-bottom: 0.25rem;
  }
}

.loaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100%;
  width: 100%;
}
