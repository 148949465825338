@import '@objectedge/pecai-storefront-ds/src/styles/variables.scss';
@import '@objectedge/pecai-storefront-ds/src/styles/colors/colors';

.main {
  width: 100%;
  min-height: 500px;
  background-size: initial;
  background-position: 89% 50%;
  background-repeat: no-repeat;
  padding: 48px 100px 0 50px;

  @media screen and (max-width: 767px) {
    min-height: unset;
    background-color: $secondary;
    background-image: none !important;
    background-size: unset;
    background-position: unset;
    background-repeat: unset;
    padding: 0;
  }

  @media screen and (min-width: 768px) and (max-width: 1180px) {
    background-position: 134% 50%;
    padding: 0 32px 24px;
  }

  @media screen and (min-width: 1600px) {
    padding: 50px 164px;
  }

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__text-container {
    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      margin-top: 24px;
    }
  }

  &__title {
    font-size: 38px;
    line-height: 130%;
    color: $white;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 29px;
      letter-spacing: unset;
    }

    &__br {
      display: inline;
      @media screen and (max-width: 500px){
        display: none;
      }
    }
  }

  &__wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    margin: 24px 0;
    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }

  &__image {
    align-self: flex-end;
    justify-self: start;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &__form {
    position: relative;
    width: 100%;
    max-width: 884px;
    height: auto;
    background-color: $white;
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin: 0 auto;

    @media screen and (max-width: 767px) {
      position: unset;
      right: unset;
      width: 100%;
    }

    &__title {
      font-size: 20px;
      line-height: 130%;
      margin-bottom: 26px;
    }

    &__plate {
      width: 100%;
      height: 52px;
    }

    &__vehicle {
      height: 52px;
    }

    &__postalcode {
      width: 100%;
      height: 52px;
    }

    &__button {
      width: 100%;
      height: 52px;
    }
  }
}
