@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.header-categories {
  display: block;
  position: fixed;
  top: 76px;
  z-index: 1002;
  width: 100%;
  background: $mid-grey;

  @include media-breakpoint-down(md) {
    top: 44px;
  }

  &__container {
    display: grid;
    grid-row-gap: 16px;
    padding: 22px 0;

    @media only screen and (min-width: 320px) {
      grid-template-columns: 1fr;
    }

    @media only screen and (min-width: 576px) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media only screen and (min-width: 1400px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    a {
      color: $white;
      text-transform: uppercase;
    }
  }
}
