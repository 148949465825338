@import '@objectedge/pecai-storefront-ds/src/styles/colors/colors';
.main {
  padding: 5px 10px;
  &__dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    p {
      color: $primary-900;
    }

    &__tittle {
      font-family: var(--font-family-montserrat) !important;
    }
  }

  &__list {
    padding: 0px 5px;
    display: none;
    max-height: calc(10 * 1rem);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 15px;
    }

    &::-webkit-scrollbar-track {
      background: #f8f8f8;
      border: 1px solid #e1e0e7;
    }

    &::-webkit-scrollbar-thumb {
      background: #fff;
      border: 1px solid #e1e0e7;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $primary-500;
    }

    &__visible {
      display: block;
    }
  }
}
