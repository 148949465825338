@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.promoBanner {
  padding: 24px;
  width: 100%;
  height: 188px;

  color: $white !important;
  text-decoration: none !important;

  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-size: cover;

  button {
    width: fit-content;
  }
}
