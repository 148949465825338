@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.main {
  max-height: 500px;
  overflow: auto;

  label {
    margin-bottom: 0;
  }

  .radio-item {
    padding: 10px 0;
  }
}
