@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.advantageCard {
  display: flex;
  width: 100%;
  max-width: 600px;

  div {
    justify-content: center;
    display: flex;

    &.iconContainer {
      border-right: 1px solid $gray-100;
      align-items: center;
      min-width: 104px;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        padding: 8px;
      }
      @include media-breakpoint-down(sm) {
        min-width: 58px;
      }
    }

    &.textContainer {
      display: flex;
      flex-direction: column;
      padding: 2em 1em;

      p:nth-child(1)::after {
        font-size: 16px;
        display: block;
        content: '';
        width: 108px;
        margin-top: 5px;
        margin-bottom: 0.5em;
        border-bottom: 2px solid $primary-100;
        border-radius: 8px;
      }
    }
  }
  background: $white;
  border: 1px solid $gray-100;
}
