.whatsappcta {
    position: fixed;
    z-index: 9000;
    bottom: 24px;
    left: 26px;
    background-color: transparent;
    border: 0;
    padding: 0;
    line-height: 1;
    height: 64px;
    width: 64px;
    border-radius: 50%;
    box-shadow: 0 0 32px 4px rgba(0, 0, 0, 0.1);

    @media screen and (min-width: 1024px) {
        left: 32px;
        bottom: 30px;
        height: 72px;
        width: 72px;
    }

    svg {
        width: 100%;
    }
}