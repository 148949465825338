@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.sub-header {
  text-align: center;
  width: 100%;
  &__yellow {
    background: $primary-500;
    padding: 8px 12px;
    color: $secondary-500;
  }

  &__dark {
    background: $secondary-500;
    padding: 8px 12px;
    color: $white;
  }
  p {
    margin: 0;
  }
}
