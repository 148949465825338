@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.grid {
  display: grid;
  grid-gap: 2em;
  justify-items: center;

  @for $i from 1 through 10 {
    &.desktop-#{$i} {
      grid-template-columns: repeat(#{$i}, 1fr);
    }

    &.hide-after-#{$i} {
      *:nth-child(n + #{$i + 1}) {
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      &.mobile-#{$i} {
        grid-template-columns: repeat(#{$i}, 1fr) !important;
      }
    }
  }
}
