@import '@objectedge/pecai-storefront-ds/src/styles/variables';
@import '@objectedge/pecai-storefront-ds/src/styles/colors/colors';

.container {
  margin: 0 3.9rem;
  padding: 2.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include media-breakpoint-down(sm) {
    margin: 0;
  }

  .heroTitle {
    width: 95%;
    max-width: 50.5rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 2.5rem;
    text-align: center;

    @include media-breakpoint-down(sm) {
      h2 {
        font-size: 2rem;
      }
    }

    h2 > span {
      color: $primary-500;
    }
  }

  .searchFrame {
    width: 95%;
    max-width: 50rem;
    background: $white;
    border: 1px solid $gray-100;
    box-shadow: 0rem 0.25rem 0.25rem rgba(151, 151, 151, 0.25);

    .searchChoiceContainer {
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      border-bottom: 1px solid $gray-100;

      .plateChoice {
        padding: 0.5rem;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        background: $white;
        color: $gray-100;

        p {
          margin: 0 0.5rem 0;
          color: $gray-100;
        }

        &Selected {
          padding: 0.5rem;
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          background: $secondary-500;
          color: $white;

          p {
            margin: 0 0.5rem 0;
            color: $white;
          }
        }
      }

      .partsChoice {
        padding: 0.5rem;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        background: $white;
        color: $gray-100;

        @include media-breakpoint-down(sm) {
          flex-direction: row-reverse;
          text-align: end;
        }

        p {
          margin: 0 0.5rem 0;
          color: $gray-100;
        }

        &Selected {
          padding: 0.5rem;
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          background: $secondary-500;
          color: $white;

          @include media-breakpoint-down(sm) {
            flex-direction: row-reverse;
            text-align: end;
          }

          p {
            margin: 0 0.5rem 0;
            color: $white;
          }

          img path {
            stroke: $white;
          }
        }
      }
    }

    .searchInputContainer {
      padding: 3rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      height: 89%;
      // border-bottom: 1px solid $gray-50;

      .advancedSearch {
        margin-top: 2rem;
        cursor: pointer;

        @include media-breakpoint-down(sm) {
          margin: 1rem 0;
        }

        p {
          text-align: center;
          text-decoration-line: underline;
          color: $primary-900;
          transition: color ease 0.1s;

          &:hover {
            color: $primary-600;
            transition: color ease 0.1s;
          }
        }
      }

      .selectVehicleContainer {
        margin-top: -2em;
        width: 100%;
        height: 100%;
      }
    }

    /* This is the bottom section of this search component. For now we
      don't have the functionality working, but the layout is ready */

    // .bottomContainer {
    //   padding: 1rem;
    //   height: 9rem;
    //   display: flex;
    //   align-items: center;
    //   justify-content: flex-end;
    //   flex-direction: column;

    //   @include media-breakpoint-down(sm) {
    //     height: 13rem;
    //   }

    //   .textAboveCards {
    //     margin-bottom: 0.5rem;
    //     width: 100%;
    //     max-width: 29rem;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     flex-direction: row;

    //     @include media-breakpoint-down(sm) {
    //       margin-bottom: 0.5rem;
    //       width: 100%;
    //     }

    //     a {
    //       cursor: pointer;

    //       p {
    //         text-align: center;
    //           text-decoration-line: underline;
    //           color: $secondary-500;
    //           transition: color ease 0.1s;

    //           &:hover {
    //             color: $secondary-200;
    //             transition: color ease 0.1s;
    //           }
    //       }
    //     }
    //   }

    //   .cardsContainer {
    //     width: 100%;
    //     max-width: 29rem;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     flex-direction: row;

    //     @include media-breakpoint-down(sm) {
    //       height: 13rem;
    //       flex-direction: column;
    //       justify-content: center;
    //       align-items: flex-start;
    //     }

    //     .cardInfo {
    //       padding: 0.25rem;
    //       min-width: 45%;
    //       max-width: 50%;
    //       height: 3.375rem;
    //       border: 1px solid $secondary-100;
    //       border-radius: 0.5rem;

    //       @include media-breakpoint-down(sm) {
    //         max-width: 100%;
    //         margin-bottom: 0.8rem;
    //       }
    //     }

    //     .upperDiv, .bottomDiv {
    //       max-width: 100%;
    //       display: flex;
    //       align-items: center;
    //       justify-content: space-between;
    //       flex-direction: row;
    //       margin-bottom: 0.25rem;

    //       .carName, .plateCode {
    //         max-width: 95%;
    //         overflow: hidden;
    //         white-space: nowrap;
    //         text-overflow: ellipsis;
    //       }

    //       .closeIcon{
    //         margin: 0 0 0.25rem 0.25rem;
    //         cursor: pointer;
    //       }
    //     }
    //   }
    // }

    // .mobileAdvSearch {
    //   width: 100%;
    //   margin-top: 0.5rem;
    //   display: flex;
    //   justify-content: flex-start;

    //   a {
    //     cursor: pointer;

    //     p {
    //       text-align: center;
    //         text-decoration-line: underline;
    //         color: $secondary-500;
    //         transition: color ease 0.1s;

    //         &:hover {
    //           color: $secondary-200;
    //           transition: color ease 0.1s;
    //         }
    //     }
    //   }
    // }
  }
}
